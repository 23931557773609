import "./styles.scss";
import Headline from "components/atoms/headline";
import Container from "react-bootstrap/Container";

const TermsOfUse = () => {
  return (
    <Container id="terms-of-use" className="termsContainer text-inside">
      <div className="text-center">
        <Headline className="display-6">TERMS OF USE</Headline>
      </div>
      <ul className="lead mt-3">
        <li>
          Rates are based on current charges and tariffs, which are subject to
          change with or without prior notice.
        </li>
        <li>Rates are subject to availability of equipment and space.</li>
        <li>
          Please always confirm empty equipment situation and available sailing
          schedule prior to booking.
        </li>
        <li>
          Written instructions and all required information and documents must
          be submitted to local documentation department prior to cut off to
          avoid late fees, or roll over, which is subject to additional charges
          such as re-stow and demurrage.
        </li>

        <li>
          Bookings canceled less than 78 hours prior documentation cut off are
          subject to a cancelation fee.
        </li>
        <li>
          If not mentioned otherwise the rates are not valid for any hazardous
          materials, overweight and/or oversized shipments and any goods that
          are subject to FDA, USDA and/or other local government regulations.
        </li>
        <li>
          Not included are any premiums for transport insurance, which can be
          arranged upon written request, or any unexpected charges such as
          detention/demurrage, customs inspections or any other fees and
          services not listed.
        </li>
        <li>
          Foreign currencies are subject to conversion risk fee of 10% of any
          outlaid duty amounts or other government fees are subject to
          disbursement fee of 7 %
        </li>
        <li>
          ALL BUSINESS UNDERTAKEN TO TERMS & CONDITIONS AS RECOMMENDED BY THE
          NATIONAL CUSTOMS BROKERS AND FORWARDERS ASSOCIATION OF AMERICA, INC.
          TEXT AVAILABLE UPON REQUEST.
        </li>
      </ul>

      <p className="lead">
        This Agreement governs the relationship between Blue Ocean Marine, its
        subsidiaries, affiliates or third-party contractors (hereinafter “Blue
        Ocean Marine”) and any visitor, customer, licensor, supplier and/or
        member of Blue Ocean Marine (hereinafter “you”) with respect to use of
        the Blue Ocean Marine website (hereinafter “the Site”). By accessing and
        viewing/using the Site, you acknowledge that you have read, understand
        and agree to be bound by the terms and conditions stated, or
        incorporated by reference, in this Agreement.
      </p>

      <h2 className="display-6 mt-5">Accuracy of Information:</h2>
      <p className="lead">
        Blue Ocean Marine may at any time make corrections, improvements and
        changes to the information, terms, service(s), software or other
        product(s) provided or described in the Site. Any changes made will be
        effective automatically without further or special notice. Your
        continued use of the Site following such changes will be deemed
        acceptance of such changes. Be sure to return to this page periodically
        to ensure familiarity with the most current version of this Agreement.
        Barring the aforementioned, Blue Ocean Marine makes no claim or
        declaration as to the accuracy of such information.
      </p>

      <h2 className="display-6 mt-5">
        Disclaimer of Warranties and Limitation of Liabilities:
      </h2>
      <p className="lead">
        The information provided by the Site is provided without warranty or
        liability — whether implied, express or statutory. Blue Ocean Marine
        does not warrant that the Site will be error or virus free. Blue Ocean
        Marine does not assume any responsibility whatsoever arising out of your
        use of the Site, regardless of whether negligence on the part of Blue
        Ocean Marine may have caused or contributed to any loss or damage
        suffered by you as a result of using or relying upon the Site. In no
        event under any theory of law, whether contract, tort, warranty or
        otherwise, shall Blue Ocean Marine have any liability to any person for
        incidental, special, consequential or exemplary damages of any
        description, including but not limited to, damages for loss of profits,
        cost of capital or business interruption expenses as a result of your
        use of the Site. Blue Ocean Marine SPECIFICALLY DISCLAIMS ANY
        WARRANTIES, EXPRESS OR IMPLIED, WITH REGARD TO THIS SITE, ITS SOFTWARE
        AND THE INFORMATION PROVIDED THROUGH THE SITE, INCLUDING BUT NOT LIMITED
        TO WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.
      </p>

      <h2 className="display-6 mt-5">Site User Licensing:</h2>
      <p className="lead">
        Blue Ocean Marine reserves the right to prohibit you from accessing the
        Site at any time with or without cause. Your access to the Site
        constitutes a revocable, nonexclusive limited license to access the Site
        under the terms and conditions of this agreement. If you are authorized
        to download, copy, display, exhibit, share, or otherwise distribute the
        content of the Site, it is for informational, purposes only. Any
        unauthorized downloading, retransmission, republication or other copying
        or modification of material posted on the Site, including trademarks,
        tradenames and service marks, may violate federal, common or civil law
        trademark law and copyright law, is prohibited and may result in legal
        action. If you download software from the Site, such software is
        licensed on a limited basis to you by Blue Ocean Marine or the owner of
        such software. Title to the software is not transferred to you. You own
        the medium on which the software is recorded, but Blue Ocean Marine
        retains all rights, title and interest in and to the software, and all
        intellectual property rights therein. Blue Ocean Marine reserves the
        right to require you to delete, destroy or otherwise remove any content
        that is used in a manner that in Blue Ocean Marine opinion is contrary
        to this Agreement. You agree that any person to whom you supply the
        content, directly or indirectly, will be advised of the terms and
        conditions of this Agreement, and that each such person is bound by
        these terms and conditions. You may in no way modify, reformulate,
        adapt, alter, adjust, change, disassemble, frame or decrypt any of the
        materials on the Site.
      </p>

      <h2 className="display-6 mt-5">Copyrights and Trademarks:</h2>
      <p className="lead">
        The trademarks, logos and service marks owned by Blue Ocean Marine,
        whether registered or unregistered, may not be used in connection with
        any product or service unless Blue Ocean Marine expressly grants such a
        right via a licensing agreement. Nothing contained on the Site should be
        construed as granting, by implication, estoppel or otherwise, any
        license or right to use any trademark without the express written
        permission of Blue Ocean Marine, its licensors or suppliers, or the
        third party owner of any such trademark. Unauthorized usage of any
        trademark is prohibited, and Blue Ocean Marine will aggressively enforce
        its intellectual property rights including but not limited to judicial
        remedies. For permission to use trademarks or copyrighted material, or
        other proprietary content, see Trademark Licensing information.
      </p>

      <h2 className="display-6 mt-5">
        Personal Information and Disclosure Statement:
      </h2>
      <p className="lead">
        Any personal information provided by customers/visitors to the Site is
        used for Blue Ocean Marine internal purposes only. Blue Ocean Marine
        does not plan to divulge, sell or lease any personal information,
        customer listings or aggregate data gathered through the Blue Ocean
        Marine Site to any third party. However, be aware that other web sites
        that may be accessed through the Site may collect personally
        identifiable information about you. The information practices of those
        third party sites linked to Blue Ocean Marine are not covered by this
        privacy statement nor are they related to Blue Ocean Marine Corporate
        and securities laws supersede the information disclosed or otherwise
        available in, through or on the site. These laws must not be qualified,
        amended, modified or supplemented by the information disclosed on the
        Site. Blue Ocean Marine makes no representation that materials or
        information available on the Site are appropriate or available for use
        in specific jurisdictions, and accessing them from jurisdictions where
        their contents are illegal is prohibited.
      </p>

      <h2 className="display-6 mt-5">Governing Legal Jurisdiction:</h2>
      <p className="lead">
        The Blue Ocean Marine website is controlled by Blue Ocean Marine from
        its offices in Alexandria,Egypt. By accessing the Site, you agree that
        all matters relating to your use of the Site shall be governed by the
        laws of the Arab Republic Of Egypt. You also agree to the exclusive
        personal jurisdiction and venue of the courts of the Arab Republic Of
        Egypt with regard to such matters.
      </p>
    </Container>
  );
};

export default TermsOfUse;

// import Container from "react-bootstrap/Container";
import Headline from "components/atoms/headline";
import "./styles.scss";

const LineUp = () => {
  const lineUpSheetUrl =
    "https://docs.google.com/spreadsheets/d/e/2PACX-1vSCl8zkcmCbfBmbkHadKZtlyyF_tZfFYDL-9ZGmvrjcqQYkIIQJHpstsFgNjELfiuhio_kH-la1xosV/pubhtml";

  return (
    // <Container className="lineContainer">
    <div id="line-up" className="lineContainer">
      <div className="text-center">
        <Headline className="display-6">LINE UP</Headline>
      </div>

      <iframe
        id="line-up"
        title="line-up"
        src={lineUpSheetUrl}
        width="100%"
        height="1000px"
        className="mt-2"
      ></iframe>
    </div>
  );
};

export default LineUp;

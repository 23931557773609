import "./styles.scss";
import Headline from "components/atoms/headline";
import Container from "react-bootstrap/Container";

const Privacy = () => {
  return (
    <Container id="privacy" className="privacyContainer text-inside">
      <div className="text-center">
        <Headline className="display-6">PRIVACY</Headline>
      </div>

      <p className="lead mt-3">
        Blue Ocean Marine is committed to Internet privacy and information
        security. In general, you can visit us on the Blue Ocean Marine Web
        without telling us who you are or revealing any information about
        yourself. Our web servers collect the IP addresses, not the e-mail
        addresses, of visitors. This information is aggregated to measure the
        number of visits, average time spent on the site, pages viewed, etc. We
        use this information to measure the use of our site and to improve the
        content of our site. There are times, however, when we may need
        information from you, such as your name, e-mail address, and mailing
        address. Usually, we use the information we collect to respond to your
        inquiry, process an order, or allow you to access specific account
        information. We will not share your information with anyone other than
        Blue Ocean Marine subsidiaries or affiliates. If you register with one
        of Blue Ocean Marine subsidiaries or affiliates on-line, they may use
        this information to provide you with customized information about
        offerings that support your specific business needs. A technology called
        “cookies” may be used to provide you with such customized information. A
        cookie is a tiny element of data that a web site can send to your
        browser, which may then be stored on your computer's hard drive so we
        can recognize you when you return. You may set your browser to notify
        you when you receive a cookie. At times we conduct on-line surveys to
        better understand our visitors' needs. When we conduct a survey, we will
        let you know how we will use your information at the time we collect it.
        We do not send unsolicited e-mail. After you provide us with personal
        information, you may receive Service Bulletins, electronic newsletters,
        or other materials of interest to you via e-mail from Blue Ocean Marine
        or its subsidiaries or affiliates. You may easily unsubscribe to future
        e-mailings by visiting the Subscribe page for that mailing. Blue Ocean
        Marine web site may contain links to other sites, such as government
        organizations and affiliates. While we try to link only to sites that
        share our high standards and respect for privacy, we are not responsible
        for other sites' content or privacy practices. Blue Ocean Marine employs
        practices that are intended to protect your privacy, including:
        <ul>
          <li>
            Notice is given about the purposes for which information is
            collected.
          </li>
          <li>
            Choice is offered to individuals to decide whether personal
            information should be disclosed either to a third party or for a
            purpose other than that for which it was originally collected.
          </li>
          <li>
            Security is provided to protect personal information from loss,
            misuse, unauthorized access, disclosure, alteration and destruction.
          </li>
          <li>
            Data integrity is maintained by making certain that collected data
            is relevant for the purposes for which it is used, and that it is
            accurate, complete and current.
          </li>
          <li>
            Access to their personal information is given to individuals so they
            may correct, amend, or delete information where it is inaccurate.{" "}
          </li>
        </ul>
      </p>

      <h2 className="display-6 mt-5">Changes to Our Policy:</h2>
      <p className="lead">
        Blue Ocean Marine reserves the right to change or remove this Privacy
        Policy at our discretion. For certain material changes to this Privacy
        Policy, we may notify you by additional means, such as by posting a
        notice on the front page of our website or sending you an e-mail.
      </p>
    </Container>
  );
};

export default Privacy;

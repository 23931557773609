import { IMAGES } from "common/assets";

export const CONTAINERS_DATA = [
  {
    containerType: "20 FT DRY CONTAINER",
    // imageUrl:
    //   "https://www.arkasline.com.tr/files/ArkasLine_tr/images/20ftdrycontainer2.gif",
    imageUrl: IMAGES.containers["20ftdrycontainer2"],
    interiorDimentions: {
      length: "5880-5895",
      width: "2330-2350",
      height: "2380-2390",
    },
    doorOpening: {
      width: "2330-2340",
      height: "H: 2275-2280",
    },
    loadCapicity: "33-33,2",
    maxPayload: "21000-28000",
    tare: "2200",
    maxGross: "30200",
  },
  {
    containerType: "40 FT DRY CONTAINER",
    // imageUrl:
    //   "https://www.arkasline.com.tr/files/ArkasLine_tr/images/40ftdrycontainer1.gif",
    imageUrl: IMAGES.containers["40ftdrycontainer1"],
    interiorDimentions: {
      length: "12024-12032",
      width: "2330-2350",
      height: "2380-2390",
    },
    doorOpening: {
      width: "2330",
      height: "H: 2275-2280",
    },
    loadCapicity: "67-67,7",
    maxPayload: "26580-28840",
    tare: "3660-3900",
    maxGross: "30480-32500",
  },
  {
    containerType: "40 FT HIGH CUBE DRY CONTAINER",
    // imageUrl:
    //   "https://www.arkasline.com.tr/files/ArkasLine_tr/images/40fthighcubedrycontaine.gif",
    imageUrl: IMAGES.containers["40fthighcubedrycontaine"],
    interiorDimentions: {
      length: "12032",
      width: "2330-2350",
      height: "2680-2695",
    },
    doorOpening: {
      width: "2330-2340",
      height: "H: 2585-2583",
    },
    loadCapicity: "76-76,4",
    maxPayload: "26400-28660",
    tare: "3840-4200",
    maxGross: "30480-32500",
  },
  {
    containerType: "20 FT OPEN TOP CONTAINER",
    // imageUrl:
    //   "https://www.arkasline.com.tr/files/ArkasLine_tr/images/20ftopentop.gif",
    imageUrl: IMAGES.containers["20ftopentop"],
    interiorDimentions: {
      length: "5880-5897",
      width: "2330-2350",
      height: "2329-2350",
    },
    doorOpening: {
      extra: "ROOF OPENING",
      width: "2200-2230",
      height: "L: 5396-5757",
    },
    loadCapicity: "32-32,8",
    maxPayload: "21800-28130",
    tare: "2350-2400",
    maxGross: "30480-30530",
  },
  {
    containerType: "40 FT OPEN TOP CONTAINER",
    // imageUrl:
    //   "https://www.arkasline.com.tr/files/ArkasLine_tr/images/40ftopentop.gif",
    imageUrl: IMAGES.containers["40ftopentop"],
    interiorDimentions: {
      length: "12007-12030",
      width: "2330-2350",
      height: "2329-2367",
    },
    doorOpening: {
      extra: "ROOF OPENING",
      width: "2190",
      height: "L: 11500-11871",
    },
    loadCapicity: "65,3-66,9",
    maxPayload: "26330-26680",
    tare: "4150",
    maxGross: "30480",
  },
  {
    containerType: "20 FT FLAT RACK CONTAINER",
    // imageUrl:
    //   "https://www.arkasline.com.tr/files/ArkasLine_tr/images/20ftflatrack.gif",
    imageUrl: IMAGES.containers["20ftflatrack"],
    interiorDimentions: {
      length: "5700-5900",
      width: "2335-2350",
      height: "2250-2276",
    },
    loadCapicity: "25-29,45",
    maxPayload: "21280",
    tare: "2720",
    maxGross: "24000",
  },
  {
    containerType: "40 FT FLAT RACK CONTAINER",
    // imageUrl:
    //   "https://www.arkasline.com.tr/files/ArkasLine_tr/images/40ftflatrack.gif",
    imageUrl: IMAGES.containers["40ftflatrack"],
    interiorDimentions: {
      length: "11700-11980",
      width: "2378",
      height: "1960",
    },
    loadCapicity: "56,6",
    maxPayload: "39000-45000",
    tare: "4320-5000",
    maxGross: "45000-50000",
  },
  {
    containerType: "40 FT PLATFORM CONTAINER",
    // imageUrl:
    //   "https://www.arkasline.com.tr/files/ArkasLine_tr/images/platform_kl.gif",
    imageUrl: IMAGES.containers["platform_kl"],
    interiorDimentions: {
      length: "11700-11980",
      width: "2380",
      height: "1955",
    },
    loadCapicity: "56,6",
    maxPayload: "39000-45000",
    tare: "4320-5000",
    maxGross: "45000-50000",
  },
  {
    containerType: "40 FT HIGH CUBE REEFER CONTAINER",
    // imageUrl:
    //   "https://www.arkasline.com.tr/files/ArkasLine_tr/images/reefer_kl.gif",
    imageUrl: IMAGES.containers["reefer_kl"],
    interiorDimentions: {
      length: "11590",
      width: "2290",
      height: "2544-2547",
      MLH: "2400",
    },
    doorOpening: {
      width: "2290-2294",
      height: "H: 2569",
    },
    loadCapicity: "67,3-67,7",
    maxPayload: "29280-29390",
    tare: "invl.: 4600-4720\n excl.: 4160-4190",
    maxGross: "34000",
  },
  {
    containerType: "20 FT ISO TANK",
    // imageUrl:
    //   "https://www.arkasline.com.tr/files/ArkasLine_tr/images/tank_kl.gif",
    imageUrl: IMAGES.containers["tank_kl"],
    loadCapicity: "-",
    maxPayload: "-",
    tare: "-",
    maxGross: "-",
  },
  {
    containerType: "40 FT PALLET WIDE",
    // imageUrl:
    //   "https://www.arkasline.com.tr/files/ArkasLine_tr/images/4045FT.gif",
    imageUrl: IMAGES.containers["4045FT"],
    interiorDimentions: {
      length: "12095",
      width: "2422",
      height: "2387",
    },
    doorOpening: {
      width: "2395-2400",
      height: "H: 2274-2279",
    },
    loadCapicity: "69,9",
    maxPayload: "30010",
    tare: "3990",
    maxGross: "34000",
  },
  {
    containerType: "45 FEET HIGH CUBE PALLET WIDE",
    // imageUrl:
    //   "https://www.arkasline.com.tr/files/ArkasLine_tr/images/4045FT.gif",
    imageUrl: IMAGES.containers["4045FT"],
    interiorDimentions: {
      length: "13532",
      width: "2426",
      height: "2694",
    },
    doorOpening: {
      width: "2363-2410",
      height: "H: 2500-2584",
    },
    loadCapicity: "89",
    maxPayload: "29740",
    tare: "4260",
    maxGross: "34000",
  },
];

import { HashRouter as Router, Routes, Route } from "react-router-dom";

import Layout from "screens/layout";
import Home from "screens/home";
import AboutUs from "screens/aboutUs";
import Services from "screens/services";
import Etools from "screens/etools";
import ContainerTracking from "screens/containerTracking";
import TransitTimes from "screens/transitTimes";
import LineUp from "screens/lineUp";
import ContactUs from "screens/contactUs";
import TermsOfUse from "screens/termsOfUse";
import ShippingLines from "screens/shippingLines";
import Privacy from "screens/privacy";
import LegalTerms from "screens/legalTerms";
import Containers from "screens/containers";
import OnlineQuotation from "screens/onlineQuotation";
import ErrorPage from "screens/errorPage";
import "./App.styles.scss";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="services" element={<Services />} />
          <Route path="etools" element={<Etools />} />
          <Route path="container-tracking" element={<ContainerTracking />} />
          <Route path="transit-times" element={<TransitTimes />} />
          <Route path="online-quotation" element={<OnlineQuotation />} />
          <Route path="line-up" element={<LineUp />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="terms-of-use" element={<TermsOfUse />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="legal-terms" element={<LegalTerms />} />
          {/* <Route path="route-planner" element={<RoutePlanner />} /> */}
          {/* <Route path="schedules" element={<Schedules />} /> */}
          {/* <Route path="shipping-lines" element={<ShippingLines />} /> */}
          {/* <Route path="load-calculator" element={<LoadCalculator />} /> */}
          {/* <Route path="world-sea-ports" element={<WorldSeaPorts />} /> */}
          <Route path="containers" element={<Containers />} />
          <Route path="*" element={<ErrorPage />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;

import "./styles.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ContactForm from "components/molecules/contactForm";
import Headline from "components/atoms/headline";
import { MapsAPIKey } from "constants/constants";
const ContactUs = () => {
  return (
    <div id="contact-us" className="contactContainer text-inside">
      <div className="text-center">
        <Headline className="display-6">CONTACT US</Headline>
      </div>
      <p className="lead text-center">
        Do you have questions about our services?
      </p>
      <p className="lead text-center">
        To get in touch with us for any reason, please call or fill out the form
        below. We respond to all inquiries and comments as soon as possible.
      </p>
      <Container>
        <Row className="gap-5">
          <Col>
            <ContactForm />
          </Col>
          <Col lg={6}>
            <iframe
              title="ContactUs Map"
              className="text-inside mt-5"
              src={`https://www.google.com/maps/embed/v1/place?key=${MapsAPIKey}&q=Blue Ocean Marine,Egypt`}
              width="100%"
              height="350"
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </Col>
        </Row>
      </Container>

      {/* <p className="lead mt-5">
        <span>Important Links:</span>
        <br />

        <span>
          Facebook :{" "}
          <a
            href="https://www.facebook.com/blueoceanmarine1984"
            target="_blank"
            rel="noopener noreferrer"
          >
            facebook.com/blueoceanmarine1984
          </a>
        </span>
        <br />
        <span>
          Instagram:{" "}
          <a
            href="https://www.instagram.com/blueoceanmarine1984"
            target="_blank"
            rel="noopener noreferrer"
          >
            instagram.com/blueoceanmarine1984
          </a>
        </span>
        <br />
        <span>
          LinkedIn:{" "}
          <a
            href="https://www.linkedin.com/company/blue-ocean-marine"
            target="_blank"
            rel="noopener noreferrer"
          >
            linkedin.com/company/blue-ocean-marine
          </a>
        </span>
        <br />
        <span>
          Sales Whatsapp:{" "}
          <a
            href="https://wa.me/201200112284"
            target="_blank"
            rel="noopener noreferrer"
          >
            wa.me/201200112284
          </a>
        </span>
        <br />
      </p> */}

      <Container>
        <p className="mt-5 text-inside text-start lh-lg ms-3">
          <span className="lead fw-bold">Alexandria, Egypt (Head Office)</span>
          <br />
          <span className="lead">
            Telephone:{" "}
            <small>
              <a href="tel:+2034841223">+2034841223</a>,{" "}
              <a href="tel:+2034804219">+2034804219</a>
            </small>
          </span>
          <br />
          <span className="lead">
            Sales Department:{" "}
            <small>
              <a href="tel:+201200112284">+20 120 011 2284</a>,{" "}
              <a href="tel:+201017889898">+20 101 788 9898</a>
            </small>
          </span>
          <br />
          <span className="lead">
            Sales Department:{" "}
            <small>
              <a href="mailto:sales@blueoceanmarine.co">
                sales@blueoceanmarine.co
              </a>
            </small>
          </span>
          <br />
          <span className="lead">
            Customer Service:{" "}
            <small>
              <a href="tel:+201501115322">+20 150 111 5322</a>,{" "}
              <a href="tel:+201202224564">+20 120 222 4564</a>
            </small>
          </span>
          <br />
          <span className="lead">
            Customer Service:{" "}
            <small>
              <a href="mailto:info@blueoceanmarine.co">
                info@blueoceanmarine.co
              </a>
            </small>
          </span>
          <br />
          <span className="lead">
            Shipping Agency Customer Service:{" "}
            <small>
              <a href="tel:+201006388830">+20 100 638 8830</a>
            </small>
          </span>
          <br />
          <span className="lead">
            Shipping Agency:{" "}
            <small>
              <a href="mailto:shipping@blueoceanmarine.co">
                shipping@blueoceanmarine.co
              </a>
            </small>
          </span>
          <br />
          <span className="lead">
            Shipping Agency Emergency 24/7:{" "}
            <small>
              <a href="tel:+201062614443">+20 106 261 4443</a>,{" "}
              <a href="tel:+201552121320">+20 155 212 1320</a>
            </small>
          </span>
        </p>
      </Container>
    </div>
  );
};

export default ContactUs;

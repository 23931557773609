import "./styles.scss";
import Headline from "components/atoms/headline";
import Container from "react-bootstrap/Container";

const Legal = () => {
  return (
    <Container id="legal-terms" className="legalContainer text-inside">
      <div className="text-center">
        <Headline className="display-6">LEGAL TERMS</Headline>
      </div>

      <p className="lead mt-3">
        Blue Ocean Marine may, at any time and without prior notice, modify
        these legal terms.
      </p>
      <p className="lead">
        By accessing this website, you accept these modifications. You are
        therefore advised to check the legal terms regularly to be aware of the
        latest modifications.
      </p>
      <p className="lead">
        <span>
          Blue Ocean Marine may, at any time and without prior notice, change or
          update the Information contained in this Website as well as its
          services detailed therein. You should check this Website regularly, or
          contact our local office.
        </span>
        <br />
        <span>
          Unless otherwise specified, or in the case of press releases, all
          information, articles, and pictures are protected by copyright and
          cannot be used without prior written agreement from Blue Ocean Marine.
        </span>
        <br />
        <span>
          In no case or no event, shall Blue Ocean Marine or any third party
          connected with Blue Ocean Marine, be liable for any direct or indirect
          damage, connected with or derived from accessing this website and/or
          using the information contained therein.
        </span>
        <br />
        <span>
          By offering this service, Blue Ocean Marine intends to update it
          regularly. However, no representation is made or warranty given
          (either express or implied) as to the completeness or accuracy of the
          information it contains.
        </span>
        <br />
        <span>
          By giving information on this website, you agree that this information
          can be given to an interested party solely for sales purposes, and
          that Blue Ocean Marine will not be liable for any direct or indirect
          loss arising from wrong or unjustified transferal of information to a
          third party, nor for the unauthorized access of any third party to
          this information. Equally, Blue Ocean Marine will not be liable for
          any inaccurate or confidential information provided in the framework
          of this service.
        </span>
        <br />
        <span>
          Any data, comment, and/or suggestion that each User contributes to the
          Blue Ocean Marine website will become the property of Blue Ocean
          Marine and may be used, without restriction, within the scope of the
          Blue Ocean Marine Group's activities. These comments and/or
          suggestions will not be treated as confidential.
        </span>
        <br />
        <span>
          In no case or no event, shall Blue Ocean Marine, its subsidiaries,
          affiliates, partners, agents, representatives, and any person involved
          in the elaboration of this website, be liable for any decision made or
          action taken after consulting this website, or for any direct or
          indirect loss, including but not limited to sales, loss of profit or
          information, incurred from accessing this website and/or acting on the
          information contained therein.
        </span>
        <br />
        <span>
          Blue Ocean Marine will not be liable for any damage resulting from a
          computer virus. Nor will it be liable for any damage resulting from
          unauthorized access by any third party to any information, data, or
          access code contained in this website.
        </span>
        <br />
        <span>
          Password-protected areas on this site are available exclusively to
          authorized people. Any unauthorized person accessing these areas will
          be prosecuted.
        </span>
        <br />
        <span>
          This website is protected by Egyptian law. Any dispute arising in
          relation to its use will take place under Egypt jurisdiction.
        </span>
      </p>
    </Container>
  );
};

export default Legal;

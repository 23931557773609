import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import { IMAGES } from "common/assets";
import "./styles.scss";

const ErrorPage = () => {
  return (
    <Container className="errorContainer">
      <Row className="px-5 align-items-center gap-5">
        <Col md={12} lg={6}>
          <p className="display-6 fw-bold">Something is not right...</p>
          <p className="lead mt-4">
            Page you are trying to open does not exist. You may have mistyped
            the address, or the page has been moved to another URL.
          </p>
          <div className="mt-5">
            <Button variant="outline-dark" size="lg" href="/">
              <small className="lead">Get back to home page</small>
            </Button>
          </div>
        </Col>
        <Col md={12} lg={5}>
          <Image src={IMAGES.error} fluid />
        </Col>
      </Row>
    </Container>
  );
};

export default ErrorPage;

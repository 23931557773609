import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import PropagateLoader from "react-spinners/PropagateLoader";
import "./styles.scss";

const OnlineQuotation = () => {
  // const location = useLocation();
  // console.log(location.search);
  const [scriptLoading, setScriptLoading] = useState(true);

  useEffect(() => {
    const scheduleDiv = document.getElementById("marketplace");
    if (scheduleDiv) {
      const observer = new ResizeObserver((entries) => {
        if (entries[0]?.contentRect?.height) {
          setScriptLoading(false);
        }
      });

      observer.observe(scheduleDiv);

      return () => {
        observer.disconnect();
      };
    }
  }, []);
  return (
    <div id="online-quotation" className="portContainer">
      <div className="text-center">
        <PropagateLoader
          loading={scriptLoading}
          color="#12a6ba"
          className="spinner"
        />
      </div>

      <div
        id="marketplace"
        data-filter='{"platform":4857,"currency":"USD"}'
      ></div>

      <Helmet>
        <script src="https://www.searates.com/freight/widget" defer></script>
      </Helmet>
    </div>
  );
};

export default OnlineQuotation;

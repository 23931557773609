import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import Toast from "react-bootstrap/Toast";
import "./styles.scss";
import { Email, RecaptchaSiteKey } from "constants/constants";
import ReCAPTCHA from "react-google-recaptcha";

const ContactForm = () => {
  const formRef = useRef<any>();
  const [isSent, setIsSent] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isToastShown, setToastShown] = React.useState(false);
  const [validated, setValidated] = React.useState(false);
  const [isNotRobot, setNotRobot] = React.useState<boolean | null>(null);

  const onFormSubmit = (e: any) => {
    if (formRef.current.checkValidity() === false || !isNotRobot) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      e.preventDefault();
      setValidated(true);
      setIsLoading(true);

      emailjs
        .sendForm(
          Email.welcomeEmail.serviceID,
          Email.welcomeEmail.templateID,
          formRef.current,
          Email.welcomeEmail.publicKey
        )
        .then(
          (welcomeEmailResult) => {
            emailjs
              .sendForm(
                Email.ownerEmail.serviceID,
                Email.ownerEmail.templateID,
                formRef.current,
                Email.ownerEmail.publicKey
              )
              .then((ownerEmailResult) => {
                setIsLoading(false);
                setIsSent(true);
                setToastShown(true);
                formRef.current.reset();
              });
          },
          (error) => {
            setIsLoading(false);
            setIsSent(false);
            setToastShown(true);
            formRef.current.reset();
          }
        );
    }
  };

  const onRecaptchaChange = (value: string | null) => {
    if (value) {
      setNotRobot(true);
    } else {
      setNotRobot(false);
    }
  };

  return (
    <Container>
      <Form
        className="mt-5"
        onSubmit={onFormSubmit}
        ref={formRef}
        noValidate
        validated={validated}
      >
        <Row>
          <Col xl={6} className="mb-3">
            <Form.Control
              required
              size="lg"
              type="text"
              name="name"
              placeholder="Name"
            />
          </Col>
          <Col xl={6} className="mb-3">
            <Form.Control
              required
              size="lg"
              type="text"
              name="company_name"
              placeholder="Company Name"
            />
          </Col>
        </Row>

        <Row>
          <Col xl={6} className="mb-3">
            <Form.Control
              required
              size="lg"
              type="email"
              name="email"
              placeholder="Email"
            />
          </Col>
          <Col xl={6} className="mb-3">
            <Form.Control
              required
              size="lg"
              type="text"
              name="phone_number"
              placeholder="Phone Number"
            />
          </Col>
        </Row>

        <Form.Control
          required
          className="mb-4"
          size="lg"
          as="textarea"
          rows={3}
          name="message"
          placeholder="Message"
        />
        <div style={{ position: "relative", zIndex: "2" }}>
          <Toast
            className="d-inline-block m-4 position-fixed end-0"
            bg={isSent ? "success" : "danger"}
            onClose={() => setToastShown(false)}
            show={isToastShown}
            delay={10000}
            autohide
            style={{ top: 80 }}
          >
            <Toast.Header>
              <strong className="me-auto">Message Delivery</strong>
            </Toast.Header>
            <Toast.Body>
              {isSent
                ? "Your message has been sent successfully!"
                : "Something went wrong, please try again after few moments!"}
            </Toast.Body>
          </Toast>
        </div>

        <ReCAPTCHA
          className="mb-4 d-flex justify-content-center"
          sitekey={RecaptchaSiteKey}
          onChange={onRecaptchaChange}
        />

        <Button
          size="lg"
          type="submit"
          className="button w-100"
          disabled={!isNotRobot || isLoading}
        >
          {isLoading ? <Spinner animation="border" /> : "Submit"}
        </Button>
      </Form>
    </Container>
  );
};

export default ContactForm;

import "./styles.scss";
import Headline from "components/atoms/headline";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

const AboutUs = () => {
  return (
    <Container id="about-us" className="aboutContainer text-inside">
      <div className="text-center">
        <Headline className="display-6">ABOUT US</Headline>
      </div>
      <p className="lead text-center">Your Full Service Logistics Solution</p>

      <h2 className="display-6">COMPANY</h2>
      <p className="lead">
        Blue Ocean Marine was established in 1984. A renowned shipping figure in
        Alexandria. Since its founding, it helped move thousands of tons of
        cargo, in the form of containers or break-bulk, between Egypt and
        international seaports.
      </p>
      <p className="lead">
        If you are looking for a reliable and efficient way to get your goods to
        the market quickly and with minimal hassle, then Blue Ocean Marine is
        the perfect choice for you. Our team of experienced professionals can
        handle all of the necessary customs clearances and paperwork for you, so
        you can concentrate on running your business.
      </p>

      <h2 className="display-6 mt-5">INTERNATIONAL FREIGHT FORWARDER:</h2>
      <p className="lead mb-5">
        As a freight forwarder, Blue Ocean Marine offers comprehensive
        international forwarding services to exporters and importers in Egypt
        and overseas. Our specialized ocean shipping teams bring a high level of
        expertise and focus to each shipment. We understand that transporters
        are major stakeholders in international commerce and our goal is to make
        the logistics process as easy as possible after you have completed the
        sale.
      </p>

      <div className="text-center meetTeam pb-3">
        <Headline className="display-6 mt-5">MEET THE TEAM</Headline>
        <p className="lead mb-4">
          <FontAwesomeIcon className="mb-2" icon={faUser} size="xl" />
          <h6>CEO</h6>
          <span>Eng. Samir Armanyous</span>
        </p>
        <p className="lead mb-4">
          <FontAwesomeIcon className="mb-2" icon={faUser} size="xl" />
          <h6>General Manager</h6>
          <span>Mrs. Reham Nadi</span>
        </p>
        <Container>
          <Row className="align-items-center">
            <Col>
              <p className="lead mb-4">
                <FontAwesomeIcon className="mb-2" icon={faUser} size="xl" />
                <h6>Business Development Manager</h6>
                <span>Eng. Peter Ywakim</span>
              </p>
            </Col>
            <Col>
              <p className="lead mb-4">
                <FontAwesomeIcon className="mb-2" icon={faUser} size="xl" />
                <h6>Logistics Manager</h6>
                <span>Mr. Andrew Tanyoss</span>
              </p>
            </Col>
            <Col>
              <p className="lead mb-4">
                <FontAwesomeIcon className="mb-2" icon={faUser} size="xl" />
                <h6>Shipping Agency Manager</h6>
                <span>Mr. Romel Wahba</span>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </Container>
  );
};

export default AboutUs;

import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import MainNavbar from "components/molecules/mainNavbar";
import MainFooter from "components/molecules/mainFooter";
import "./styles.scss";
import MessengerChat from "components/molecules/messengerChat";

const Layout = () => {
  const location = useLocation();
  const [className, setClassName] = useState("");

  useEffect(() => {
    switch (location.pathname) {
      // case "/":
      //   setClassName("home-background");
      //   break;
      case "/about-us":
        setClassName("about-us-background");
        break;
      case "/etools":
        setClassName("etools-background");
        break;
      case "/contact-us":
        setClassName("contact-us-background");
        break;
      case "/terms-of-use":
      case "/privacy":
      case "/legal-terms":
        setClassName("terms-of-use-background");
        break;
      default:
        setClassName("neutral-background");
        break;
    }

    if (location.pathname === "/services") {
      switch (location.hash) {
        case "#ocean-freight":
          setClassName("ocean-frieght-background");
          break;
        case "#ocean-freight#full-container":
          setClassName("ocean-freight-fcl-background");
          break;
        case "#ocean-freight#less-than-full-container":
          setClassName("ocean-freight-lcl-background");
          break;
        case "#shipping-agency":
          setClassName("shipping-agency-background");
          break;
        case "#warehousing":
          setClassName("warehousing-background");
          break;
        case "#project-cargo":
          setClassName("project-cargo-background");
          break;
        case "#customs-clearance":
          setClassName("customs-clearance-background");
          break;
        case "#inland-haulage":
          setClassName("inland-haulage-background");
          break;
        default:
          setClassName("ocean-frieght-background");
          break;
      }
    }
  }, [location.hash, location.pathname]);

  return (
    <>
      <div className={className}>
        <MainNavbar className={className} />
        <MessengerChat />
        <Outlet />
      </div>
      <MainFooter />
    </>
  );
};

export default Layout;

export type HOME_SERVICES_DATA_TYPE = {
  title: string;
  href: string;
};

export const HOME_SERVICES_DATA = {
  oceanFrieght: [
    {
      title: "FCL",
      href: "/#services#ocean-freight#full-container",
    },
    {
      title: "LCL",
      href: "/#services#ocean-freight#less-than-full-container",
    },
    {
      title: "Reefer",
      href: "/#services#ocean-freight",
    },
  ],
  digitalBusinessSolutions: [
    {
      title: "Online Quotation",
      href: "/#online-quotation",
    },
    {
      title: "Container Tracking",
      href: "/#container-tracking",
    },
    {
      title: "Vessel Schedules",
      href: "/#transit-times",
    },
    {
      title: "Transit Times",
      href: "/#transit-times",
    },
    {
      title: "Line Up",
      href: "/#line-up",
    },
  ],
};

import CardButton from "components/atoms/cardButton";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Carousel from "react-bootstrap/Carousel";

import { IMAGES } from "common/assets";
import c2 from "common/assets/images/certifications/c2.svg";
import "./styles.scss";
import Headline from "components/atoms/headline";
import {
  faBoxOpen,
  faCalendarDays,
  faCheckToSlot,
  faCrown,
  faDolly,
  faLaptop,
  faMagnifyingGlass,
  faTruckFront,
} from "@fortawesome/free-solid-svg-icons";
import FeatureItem from "components/atoms/featureItem";
import { useEffect, useState } from "react";
import ServicesComponent from "components/molecules/servicesComponent";
import { MapsAPIKey } from "constants/constants";
import { Helmet } from "react-helmet-async";
import PropagateLoader from "react-spinners/PropagateLoader";

const Home = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [scriptLoading, setScriptLoading] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const scheduleDiv = document.getElementById("main-filter");
    if (scheduleDiv) {
      const observer = new ResizeObserver((entries) => {
        if (entries[0]?.contentRect?.height) {
          setScriptLoading(false);
        }
      });

      observer.observe(scheduleDiv);

      return () => {
        observer.disconnect();
      };
    }
  }, []);

  return (
    <div className={"home-container"}>
      <div className="firstContainer">
        <h1 className="display-4 firstContainerTitle">
          GET YOUR INSTANT{" "}
          <span className="firstContainerSectitle">FREIGHT QUOTE</span>
        </h1>

        <div className="text-center">
          <PropagateLoader
            loading={scriptLoading}
            color="white"
            className="spinner"
          />
        </div>

        <div
          className="contentContainer"
          id="main-filter"
          data-filter='{
              "filters":{
                "rates":{
                      "title":"rates",
                      "link":"#online-quotation",
                      "shippingType":{
                          "sea":true,
                          "land":true,
                          "air":true
                      },
                      "defaultShippingType":"sea"
                  },
                "tracking":{
                      "title":"tracking",
                      "link":"#container-tracking"
                    },
                "schedules":{
                      "title":"schedules",
                      "link":"#transit-times"
                    }
              },
              "filtersAutoFocus":true,
              "view":{
                  "shadow":true,
                  "displayType":"long",
                  "alignFilter":"center",
                  "alignTabs":"left"
              },
              "colors":{
                  "accentColor":"#0088FF",
                  "textColor":"#000C37",
                  "placeholderColor":"#C9CFDB",
                  "filterBackgroundColor":"#FFFFFF"
              }
          }'
          data-loading="defer"
        ></div>

        <Helmet>
          <script
            src="https://www.searates.com/platform/filter/"
            defer
          ></script>
        </Helmet>

        <Container>
          <Row className="contentContainer">
            <Col className="mb-4">
              <CardButton
                href="/#container-tracking"
                label="Container Tracking"
                icon={faMagnifyingGlass}
              />
            </Col>
            {/* <Col className="mb-4">
              <CardButton
                href="/#load-calculator"
                label="Load Calculator"
                icon={faCalculator}
              />
            </Col> */}
            {/* <Col className="mb-4">
              <CardButton
                href="/#world-sea-ports"
                label="World Sea Ports"
                icon={faGlobe}
              />
            </Col> */}
            <Col className="mb-4">
              <CardButton
                href="/#transit-times"
                label="Transit Times"
                icon={faCalendarDays}
              />
            </Col>
            <Col className="mb-4">
              <CardButton href="/#line-up" label="Line Up" icon={faDolly} />
            </Col>
            <Col className="mb-4">
              <CardButton href="/#services" label="Services" icon={faLaptop} />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="secondContainer">
        <div className="about">
          <Container>
            <Row className="gap-5 align-items-center justify-items-center">
              <Col lg={6} className="text-start">
                <Headline className="mt-5">ABOUT US</Headline>
                <p>
                  Blue Ocean Marine was established in 1984. A renowned shipping
                  figure in Alexandria. Since its founding, it helped move
                  thousands of tons of cargo, in the form of containers or
                  break-bulk, between Egypt and international seaports. The
                  company has acted as an agent of many shipping lines.
                </p>

                <p>
                  If you are looking for a reliable and efficient way to get
                  your goods to the market quickly and with minimal hassle, then
                  Blue Ocean Marine is the perfect choice for you. We have a
                  team of experienced professionals who can handle all of the
                  necessary customs clearances and paperwork for you, so you can
                  concentrate on running your business.
                </p>

                <p>
                  As a freight forwarder, Blue Ocean Marine offers comprehensive
                  international forwarding services to exporters and importers
                  in Egypt and overseas. Our specialized ocean shipping teams
                  bring a high level of expertise and focus to each shipment. We
                  understand that transporters are major stakeholders in
                  international commerce and our goal is to make the logistics
                  process as easy as possible after you have completed the sale.
                </p>

                <Button href="/#about-us" className="button mt-4" size="lg">
                  Learn More
                </Button>
              </Col>
              <Col>
                <Image
                  src={IMAGES.about}
                  height={500}
                  width={500}
                  rounded
                  fluid
                />
              </Col>
            </Row>
          </Container>
        </div>

        <div className="mt-5">
          <Headline className="mt-5">
            GLOBAL FREIGHT FORWARDING SERVICES
          </Headline>
          <p>
            Let our experienced professionals manage your shipping process from
            start to finish - expertly and knowledgeably
          </p>
          <ServicesComponent />
          {/* <Container>
            <Row className="mt-5 mb-5">
              <Col className="mb-4">
                <CardButton
                  href="#services#ocean-freight"
                  label="OCEAN FREIGHT"
                  isMini={false}
                  icon={faShip}
                />
              </Col>
              <Col className="mb-4">
                <CardButton
                  href="#services#shipping-agency"
                  label="SHIPPING AGENCY"
                  isMini={false}
                  icon={faDolly}
                />
              </Col>
              <Col className="mb-4">
                <CardButton
                  href="#services#warehousing"
                  label="WAREHOUSING"
                  isMini={false}
                  icon={faWarehouse}
                />
              </Col>
              <Col className="mb-4">
                <CardButton
                  href="#services#project-cargo"
                  label="PROJECT CARGO"
                  isMini={false}
                  icon={faTruckFast}
                />
              </Col>
            </Row>
          </Container> */}
        </div>
      </div>

      <div className="thirdContainer">
        <div className="text-inside">
          <Headline className="mt-5">Personalized & top-notch service</Headline>
          <p className="lead">
            Get help throughout the entire process with dedicated sales and
            operations account managers always at your service
          </p>
          <p className="lead">
            Customized international shipping rates specially negotiated with
            the carriers for you
          </p>
        </div>
        <div className="pushed-down text-inside">
          <Headline className="mt-5">WHAT MAKES US SPECIAL?</Headline>
          {/* <p className="lead">
            Over 42 000 dedicated employees, working in 17 regional clusters
            around the globe, deliver operational excellence.
          </p> */}
          {/* <Container>
            <Row className="gap-3 mt-5 align-items-center justify-items-center">
              <Col>
                <p className="lead thirdContainerItem mb-5">
                  PACKAGING AND STORAGE
                </p>
                <p className="lead thirdContainerItem mb-5">
                  WAREHOUSING SERVICE
                </p>
              </Col>
              <Col>
                <p className="lead thirdContainerItem mb-5">GROUND TRANSPORT</p>
                <p className="lead thirdContainerItem mb-5">
                  LOGISTICS SERVICES
                </p>
              </Col>
            </Row>
          </Container> */}

          <Container>
            <Row className="my-5">
              <Col lg={3} xs={6}>
                <FeatureItem
                  label="30+"
                  sublabel="Years of Experience"
                  icon={faCheckToSlot}
                />
              </Col>
              <Col lg={3} xs={6}>
                <FeatureItem
                  label="4000+"
                  sublabel="Containers Annually"
                  icon={faBoxOpen}
                />
              </Col>
              <Col lg={3} xs={6}>
                <FeatureItem
                  label="7000+"
                  sublabel="Global Agents"
                  icon={faTruckFront}
                />
              </Col>
              <Col lg={3} xs={6}>
                <FeatureItem
                  label="400+"
                  sublabel="Loyal Clients"
                  icon={faCrown}
                />
              </Col>
            </Row>
          </Container>

          <Button href="/#services" className="button text-inside" size="lg">
            More Info
          </Button>
        </div>
      </div>

      {/* <div className="fourthContainer">
        <h2>OUR CUSTOMERS</h2>
        <Image src={IMAGES.customers} fluid />
      </div> */}

      {/* <div className="fifthContainer bg-light mt-4">
        <Row className="align-items-center">
          <Col xm={1} lg={6} className="text-start">
            <h2>WHAT MAKES US SPECIAL?</h2>
            <p>
              Over 42 000 dedicated employees, working in 17 regional clusters
              around the globe, deliver operational excellence.
            </p>
            <Row className="gap-3 mt-5" lg={3}>
              <Col>
                <p className="lead fifthContainerItem">PACKAGING AND STORAGE</p>
              </Col>
              <Col>
                <p className="lead fifthContainerItem">WAREHOUSING SERVICE</p>
              </Col>
              <Col>
                <p className="lead fifthContainerItem">GROUND TRANSPORT</p>
              </Col>
              <Col>
                <p className="lead fifthContainerItem">LOGISTICS SERVICES</p>
              </Col>
            </Row>
          </Col>
          <Col xm={1} lg={6}>
            <div className="fifthContainerForm">
              <h2 className="text-inside">REQUEST A FREE QUOTE</h2>
              <Button href="/" className="button text-inside">
                Submit
              </Button>
            </div>
          </Col>
        </Row>
      </div> */}

      <div className="sixthContainer">
        <Headline className="mt-5">CERTIFICATION</Headline>
        {!isMobile ? (
          <div className="d-flex">
            <Row className="mt-3 align-items-center w-100">
              <Col xl={2} md={4} className="mb-3">
                <Image height={100} src={IMAGES.c1} />
              </Col>
              <Col xl={2} md={4} className="mb-3">
                <Image height={110} src={c2} />
              </Col>
              <Col xl={2} md={4} className="mb-3">
                <Image height={100} src={IMAGES.c3} />
              </Col>
              <Col xl={2} md={4} className="mb-3">
                <Image height={100} src={IMAGES.c4} />
              </Col>
              <Col xl={2} md={4} className="mb-3">
                <Image width={150} src={IMAGES.c6} />
              </Col>
              <Col xl={2} md={4} className="mb-3">
                <Image width={180} src={IMAGES.c5} />
              </Col>
            </Row>
          </div>
        ) : (
          <Carousel data-bs-theme="dark" indicators={false}>
            <Carousel.Item interval={1000}>
              <Image height={140} src={IMAGES.c1} />
            </Carousel.Item>
            <Carousel.Item interval={1000}>
              <Image height={140} src={c2} />
            </Carousel.Item>
            <Carousel.Item interval={1000}>
              <Image height={140} src={IMAGES.c3} />
            </Carousel.Item>
            <Carousel.Item interval={1000}>
              <Image height={140} src={IMAGES.c4} />
            </Carousel.Item>
            <Carousel.Item interval={1000}>
              <Image height={120} src={IMAGES.c6} />
            </Carousel.Item>
            <Carousel.Item interval={1000}>
              <Image height={110} src={IMAGES.c5} />
            </Carousel.Item>
          </Carousel>
        )}
      </div>

      <div className="seventhContainer">
        <Headline className="text-inside">
          WE WOULD LOVE TO HEAR FROM YOU.
        </Headline>
        <p className="lead text-inside">
          Whether you need a quote, have a question or want to chat.
        </p>
        <Container>
          <Row className="align-items-center">
            <Col md={12} lg={6}>
              <p className="mt-5 text-inside text-start lh-lg">
                <span className="lead fw-bold">
                  Alexandria, Egypt (Head Office)
                </span>
                <br />
                <span className="lead">
                  Telephone:{" "}
                  <small>
                    <a href="tel:+2034841223">+2034841223</a>,{" "}
                    <a href="tel:+2034804219">+2034804219</a>
                  </small>
                </span>
                <br />
                <span className="lead">
                  Sales Department:{" "}
                  <small>
                    <a href="tel:+201200112284">+20 120 011 2284</a>,{" "}
                    <a href="tel:+201017889898">+20 101 788 9898</a>
                  </small>
                </span>
                <br />
                <span className="lead">
                  Customer Service:{" "}
                  <small>
                    <a href="tel:+201501115322">+20 150 111 5322</a>,{" "}
                    <a href="tel:+201202224564">+20 120 222 4564</a>
                  </small>
                </span>
                <br />
                <span className="lead">
                  Shipping Agency Customer Service:{" "}
                  <small>
                    <a href="tel:+201006388830">+20 100 638 8830</a>
                  </small>
                </span>
                <br />
                <span className="lead">
                  Shipping Agency:{" "}
                  <small>
                    <a href="mailto:shipping@blueoceanmarine.co">
                      shipping@blueoceanmarine.co
                    </a>
                  </small>
                </span>
                <br />
                <span className="lead">
                  Shipping Agency Emergency 24/7:{" "}
                  <small>
                    <a href="tel:+201062614443">+20 106 261 4443</a>,{" "}
                    <a href="tel:+201552121320">+20 155 212 1320</a>
                  </small>
                </span>
              </p>
            </Col>
            <Col>
              <iframe
                title="Home Map"
                className="text-inside mt-5"
                src={`https://www.google.com/maps/embed/v1/place?key=${MapsAPIKey}&q=Blue Ocean Marine,Egypt`}
                width="100%"
                height="350"
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Home;

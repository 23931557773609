export const Email = {
  ownerEmail: {
    serviceID: "service_ifgukil",
    templateID: "template_n81f0sb",
    publicKey: "rR5iAHFLVofbKNsXg",
  },
  welcomeEmail: {
    serviceID: "service_ifgukil",
    templateID: "template_pss1tkd",
    publicKey: "rR5iAHFLVofbKNsXg",
  },
};
export const RecaptchaSiteKey = "6LdyT2MpAAAAAIvpCzNfztbXTV6aDxE7vkP_Pd9N";
export const MapsAPIKey = "AIzaSyA8gHZcOwi8Rk-XHyp00-FqWRFOlcNBzwo";
export const metaDeveloperAppID = "1081532406219484";
export const facebookPageID = "395510727256600";

export const IMAGES = {
  about: require("./images/about.png"),
  hearFomYou: require("./images/hearFromYou.png"),
  logo: require("./images/logo.png"),
  logoWhite: require("./images/logo-white.png"),
  personalizedService: require("./images/personalizedService.png"),
  c1: require("./images/certifications/c1.png"),
  c3: require("./images/certifications/c3.png"),
  c4: require("./images/certifications/c4.png"),
  c5: require("./images/certifications/c5.png"),
  c6: require("./images/certifications/c6.png"),
  containers: {
    "20ftdrycontainer2": require("./images/containers/20ftdrycontainer2.png"),
    "20ftflatrack": require("./images/containers/20ftflatrack.png"),
    "20ftopentop": require("./images/containers/20ftopentop.png"),
    "40ftdrycontainer1": require("./images/containers/40ftdrycontainer1.png"),
    "40ftflatrack": require("./images/containers/40ftflatrack.png"),
    "40fthighcubedrycontaine": require("./images/containers/40fthighcubedrycontaine.png"),
    "40ftopentop": require("./images/containers/40ftopentop.png"),
    "4045FT": require("./images/containers/4045FT.png"),
    platform_kl: require("./images/containers/platform_kl.png"),
    reefer_kl: require("./images/containers/reefer_kl.png"),
    tank_kl: require("./images/containers/tank_kl.png"),
  },
  error: require("./images/404.gif"),
};

import Button from "react-bootstrap/Button";

import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition, faNotdef } from "@fortawesome/free-solid-svg-icons";

type props = {
  label: string;
  href: string;
  isMini?: boolean;
  icon?: IconDefinition;
};

const CardButton = ({ label, href, isMini = true, icon = faNotdef }: props) => {
  return isMini ? (
    <div className="cardButtonMiniContainer">
      <Button href={href} className="cardButtonMini">
        <FontAwesomeIcon className="icon me-4" icon={icon} size="2xl" />
        <h5 className="cardButtonMiniTitle text-uppercase">{label}</h5>
      </Button>
    </div>
  ) : (
    <div className="cardButtonLargeContainer">
      <Button href={href} className="cardButtonLarge">
        <div>
          <FontAwesomeIcon className="icon" icon={icon} size="2xl" />
          <h4 className="cardButtonLargeTitle text-uppercase mt-4">{label}</h4>
        </div>
      </Button>
    </div>
  );
};

export default CardButton;

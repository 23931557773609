import { IMAGES } from "common/assets";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./styles.scss";
import Image from "react-bootstrap/Image";
import { useLocation } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBoxOpen,
  faDolly,
  faShip,
  faTruckFast,
  faTruckFront,
  faWarehouse,
  faAnchor,
  faCalendarDays,
  faClock,
  faMagnifyingGlass,
  faEllipsis,
} from "@fortawesome/free-solid-svg-icons";

type props = {
  className: string;
};

const MainNavbar = ({ className }: props) => {
  const location = useLocation();

  const [isServicesDropdownOpen, setServicesDropdownOpen] = useState(false);
  const showServicesDropdownOpen: React.MouseEventHandler<HTMLElement> = (
    _
  ) => {
    setServicesDropdownOpen(true);
  };
  const hideServicesDropdownOpen: React.MouseEventHandler<HTMLElement> = (
    _
  ) => {
    setServicesDropdownOpen(false);
  };

  const [isEtoolsDropdownOpen, setEtoolsDropdownOpen] = useState(false);
  const showEtoolsDropdownOpen: React.MouseEventHandler<HTMLElement> = (_) => {
    setEtoolsDropdownOpen(true);
  };
  const hideEtoolsDropdownOpen: React.MouseEventHandler<HTMLElement> = (_) => {
    setEtoolsDropdownOpen(false);
  };

  return (
    <Navbar
      expand="lg"
      // className={`backgroundForAll navbar-${className} py-2`}
      className={`backgroundForAll py-2`}
      data-bs-theme="light"
      fixed="top"
      collapseOnSelect
    >
      <Container>
        <Navbar.Brand href="/">
          <div className="logoContainer">
            <Image src={IMAGES.logo} alt="Blue Ocean Marine" fluid />
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav
            variant="underline"
            activeKey={location.pathname}
            className="ms-auto"
          >
            <Nav.Item>
              <NavDropdown
                title="Services"
                id="services-nav-dropdown"
                show={isServicesDropdownOpen}
                // onToggle={() => {
                //   if (!isMobile) {
                //     window.location.href = "/#services";
                //   }
                // }}
                onMouseEnter={showServicesDropdownOpen}
                onMouseLeave={hideServicesDropdownOpen}
                className="navDropdownContainer"
              >
                <NavDropdown.Item
                  href="/#services#ocean-freight"
                  bsPrefix="navDropdown"
                >
                  <FontAwesomeIcon
                    className="icon me-3"
                    icon={faShip}
                    size="lg"
                    width={25}
                  />
                  Ocean Freight
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/#services#shipping-agency"
                  bsPrefix="navDropdown"
                >
                  <FontAwesomeIcon
                    className="icon me-3"
                    icon={faDolly}
                    size="lg"
                    width={25}
                  />
                  Shipping Agency
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/#services#warehousing"
                  bsPrefix="navDropdown"
                >
                  <FontAwesomeIcon
                    className="icon me-3"
                    icon={faWarehouse}
                    size="lg"
                    width={25}
                  />
                  Warehousing
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/#services#project-cargo"
                  bsPrefix="navDropdown"
                >
                  <FontAwesomeIcon
                    className="icon me-3"
                    icon={faTruckFast}
                    size="lg"
                    width={25}
                  />
                  Project Cargo
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/#services#customs-clearance"
                  bsPrefix="navDropdown"
                >
                  <FontAwesomeIcon
                    className="icon me-3"
                    icon={faBoxOpen}
                    size="lg"
                    width={25}
                  />
                  Customs Clearance
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/#services#inland-haulage"
                  bsPrefix="navDropdown"
                >
                  <FontAwesomeIcon
                    className="icon me-3"
                    icon={faTruckFront}
                    size="lg"
                    width={25}
                  />
                  Inland Haulage
                </NavDropdown.Item>

                <hr />
                <NavDropdown.Item href="/#services" bsPrefix="navDropdown">
                  <FontAwesomeIcon
                    className="icon me-3"
                    icon={faEllipsis}
                    size="lg"
                    width={25}
                  />
                  Find a Service
                </NavDropdown.Item>
              </NavDropdown>
            </Nav.Item>

            <Nav.Item>
              <NavDropdown
                title="Etools"
                id="etools-nav-dropdown"
                show={isEtoolsDropdownOpen}
                // onToggle={() => {
                //   if (!isMobile) {
                //     window.location.href = "/#etools";
                //   }
                // }}
                onMouseEnter={showEtoolsDropdownOpen}
                onMouseLeave={hideEtoolsDropdownOpen}
              >
                <NavDropdown.Item
                  href="/#online-quotation"
                  bsPrefix="navDropdown"
                >
                  <FontAwesomeIcon
                    className="icon me-3"
                    icon={faAnchor}
                    size="lg"
                    width={25}
                  />
                  Online Quotation
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/#container-tracking"
                  bsPrefix="navDropdown"
                >
                  <FontAwesomeIcon
                    className="icon me-3"
                    icon={faMagnifyingGlass}
                    size="lg"
                    width={25}
                  />
                  Container Tracking
                </NavDropdown.Item>
                {/* <NavDropdown.Item href="/#route-planner" bsPrefix="navDropdown">
                  <FontAwesomeIcon
                    className="icon me-3"
                    icon={faRoute}
                    size="lg"
                    width={25}
                  />
                  Route Planner
                </NavDropdown.Item> */}
                <NavDropdown.Item href="/#transit-times" bsPrefix="navDropdown">
                  <FontAwesomeIcon
                    className="icon me-3"
                    icon={faClock}
                    size="lg"
                    width={25}
                  />
                  Vessel Schedules
                </NavDropdown.Item>
                <NavDropdown.Item href="/#transit-times" bsPrefix="navDropdown">
                  <FontAwesomeIcon
                    className="icon me-3"
                    icon={faCalendarDays}
                    size="lg"
                    width={25}
                  />
                  Transit Times
                </NavDropdown.Item>
                {/* <NavDropdown.Item href="/#schedules" bsPrefix="navDropdown">
                  <FontAwesomeIcon
                    className="icon me-3"
                    icon={faClock}
                    size="lg"
                    width={25}
                  />
                  Ship Schedules
                </NavDropdown.Item> */}
                {/* <NavDropdown.Item
                  href="/#shipping-lines"
                  bsPrefix="navDropdown"
                >
                  <FontAwesomeIcon
                    className="icon me-3"
                    icon={faCartFlatbed}
                    size="lg"
                    width={25}
                  />
                  Shipping Lines
                </NavDropdown.Item> */}
                {/* <NavDropdown.Item
                  href="/#load-calculator"
                  bsPrefix="navDropdown"
                >
                  <FontAwesomeIcon
                    className="icon me-3"
                    icon={faCalculator}
                    size="lg"
                    width={25}
                  />
                  Load Calculator
                </NavDropdown.Item> */}
                {/* <NavDropdown.Item
                  href="/#world-sea-ports"
                  bsPrefix="navDropdown"
                >
                  <FontAwesomeIcon
                    className="icon me-3"
                    icon={faGlobe}
                    size="lg"
                    width={25}
                  />
                  World Sea Ports
                </NavDropdown.Item> */}
                {/* <NavDropdown.Item href="/#online-quotation" bsPrefix="navDropdown">
                  <FontAwesomeIcon
                    className="icon me-3"
                    icon={faAnchor}
                    size="lg"
                    width={25}
                  />
                  Port Distance
                </NavDropdown.Item> */}
                <NavDropdown.Item href="/#line-up" bsPrefix="navDropdown">
                  <FontAwesomeIcon
                    className="icon me-3"
                    icon={faDolly}
                    size="lg"
                    width={25}
                  />
                  Line Up
                </NavDropdown.Item>

                <hr />
                <NavDropdown.Item href="/#etools" bsPrefix="navDropdown">
                  <FontAwesomeIcon
                    className="icon me-3"
                    icon={faEllipsis}
                    size="lg"
                    width={25}
                  />
                  Find a Tool
                </NavDropdown.Item>
              </NavDropdown>
            </Nav.Item>
            {/* <Nav.Item>
              <Nav.Link href="/#container-tracking">Tracking</Nav.Link>
            </Nav.Item> */}

            {/* <Nav.Item>
              <Nav.Link href="/#online-quotation">Quotation</Nav.Link>
            </Nav.Item> */}

            {/* <Nav.Item>
              <Nav.Link href="/#line-up">Line Up</Nav.Link>
            </Nav.Item> */}

            <Nav.Item>
              <Nav.Link href="/#containers">Containers</Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link href="/#about-us">About Us</Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link href="/#contact-us">Contact Us</Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default MainNavbar;

import "./styles.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition, faNotdef } from "@fortawesome/free-solid-svg-icons";
import { HOME_SERVICES_DATA_TYPE } from "constants/home_services_data";

type props = {
  icon?: IconDefinition;
  title?: string;
  mainHref?: string;
  children?: HOME_SERVICES_DATA_TYPE[];
};

const ServicesItem = ({
  icon = faNotdef,
  title = "Title",
  mainHref = "",
  children = [],
}: props) => {
  return (
    <Col className="servicesItem">
      <a className="servicesItemA" href={mainHref}>
        <FontAwesomeIcon className="servicesItemIcon mb-3" icon={icon} size="3x" />
        <h6 className="lead fw-bold lh-base mx-5 mb-4">{title}</h6>
        <div className="children">
          {children.map((child, index) => {
            return (
              <a
                key={`${title}${index}`}
                href={child.href}
                className="lead mb-1"
              >
                {child.title}
              </a>
            );
          })}
        </div>
      </a>
    </Col>
  );
};

export default ServicesItem;

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Headline from "components/atoms/headline";
import CardButton from "components/atoms/cardButton";
import {
  faAnchor,
  faCalendarDays,
  faClock,
  faDolly,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import "./styles.scss";

const Etools = () => {
  return (
    <div id="etools" className="etoolsContainer">
      <div className="text-center">
        <Headline className="display-6 text-inside">ETOOLS</Headline>
      </div>
      <Container>
        <Row className="gap-3 mt-5 mb-5 text-inside">
          <Col className="mb-4">
            <CardButton
              href="/#online-quotation"
              label="ONLINE QUOTATION"
              isMini={false}
              icon={faAnchor}
            />
          </Col>
          <Col className="mb-4">
            <CardButton
              href="/#container-tracking"
              label="CONTAINER TRACKING"
              isMini={false}
              icon={faMagnifyingGlass}
            />
          </Col>

          <Col className="mb-4">
            <CardButton
              href="/#transit-times"
              label="VESSEL SCHEUDLES"
              isMini={false}
              icon={faClock}
            />
          </Col>
          <Col className="mb-4">
            <CardButton
              href="/#transit-times"
              label="TRANSIT TIMES"
              isMini={false}
              icon={faCalendarDays}
            />
          </Col>
          <Col className="mb-4">
            <CardButton
              href="/#line-up"
              label="LINE UP"
              isMini={false}
              icon={faDolly}
            />
          </Col>
          {/* <Col className="mb-4">
            <CardButton
              href="/#route-planner"
              label="ROUTE PLANNER"
              isMini={false}
              icon={faRoute}
            />
          </Col> */}
          {/* <Col className="mb-4">
            <CardButton
              href="/#shipping-lines"
              label="SHIPPING LINES"
              isMini={false}
              icon={faCartFlatbed}
            />
          </Col> */}
          {/* <Col className="mb-4">
            <CardButton
              href="/#load-calculator"
              label="LOAD CALCULATOR"
              isMini={false}
              icon={faCalculator}
            />
          </Col> */}
          {/* <Col className="mb-4">
            <CardButton
              href="/#world-sea-ports"
              label="WORLD SEA PORTS & TERMINALS"
              isMini={false}
              icon={faGlobe}
            />
          </Col> */}
        </Row>
      </Container>
    </div>
  );
};

export default Etools;

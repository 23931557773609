import "./styles.scss";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Container from "react-bootstrap/Container";
import { useLocation } from "react-router-dom";
import Headline from "components/atoms/headline";
import { useEffect } from "react";

const Services = () => {
  const location = useLocation();

  useEffect(() => {
    const element = document.getElementById("services");
    if (element) {
      element.scrollIntoView();
    }
  }, [location.hash]);

  return (
    <div id="services" className="servicesContainer">
      <div className="text-center text-inside">
        <Headline className="display-6">SERVICES</Headline>
      </div>
      <div className="mt-5 text-inside">
        <Tab.Container
          activeKey={location.hash !== "" ? location.hash : "#ocean-freight"}
        >
          <Container>
            <Row className="gap-5">
              <Col xs={11} md={4} className="bg-dark px-3 py-3">
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link
                      href="/#services#ocean-freight"
                      eventKey="#ocean-freight"
                    >
                      OCEAN FREIGHT
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="ms-4">
                    <Nav.Link
                      href="/#services#ocean-freight#full-container"
                      eventKey="#ocean-freight#full-container"
                    >
                      FULL CONTAINER
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="ms-4">
                    <Nav.Link
                      href="/#services#ocean-freight#less-than-full-container"
                      eventKey="#ocean-freight#less-than-full-container"
                    >
                      LESS-THAN-FULL CONTAINER
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="/#services#shipping-agency"
                      eventKey="#shipping-agency"
                    >
                      SHIPPING AGENCY
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="/#services#warehousing"
                      eventKey="#warehousing"
                    >
                      WAREHOUSING
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="/#services#project-cargo"
                      eventKey="#project-cargo"
                    >
                      PROJECT CARGO
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="/#services#customs-clearance"
                      eventKey="#customs-clearance"
                    >
                      CUSTOMS CLEARANCE
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="/#services#inland-haulage"
                      eventKey="#inland-haulage"
                    >
                      INLAND HAULAGE
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={7}>
                <Tab.Content>
                  <Tab.Pane eventKey="#ocean-freight">
                    <div>
                      <h2 className="lead">OCEAN FREIGHT</h2>
                      <p className="mt-3">
                        Blue Ocean Marine is an experienced ocean freight
                        forwarding company. We offer a full range of sea import
                        and sea export services, including full container load
                        (FCL) and less-than-container load (LCL). Through our
                        partners, we can integrate ocean shipping with other
                        modes of transport — road and rail — allowing us to
                        offer door-to-door and door-to-port services. Plus, we
                        provide customs brokerage and documentation services so
                        your cargo crosses borders as efficiently and quickly as
                        possible. Blue Ocean Marine's technology enables you to
                        track the progress of your shipment in real time.
                      </p>

                      <ul>
                        <li>
                          <a
                            href="/#services#ocean-freight#full-container"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Full Container
                          </a>
                        </li>
                        <li>
                          <a
                            href="/#services#ocean-freight#less-than-full-container"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Less-Than-Full Container
                          </a>
                        </li>
                      </ul>
                      <p>
                        <span>
                          While we don't own the vessels, we make all the
                          arrangements for shipping your cargo overseas. We
                          maintain great working relationships with a wide
                          network of ship owners, ensuring the best possible
                          solution for forwarding your freight.
                        </span>
                        <br />
                        <span>
                          Our services can include arranging for a truck to
                          transport your container between your factory and
                          departure port, and between the arrival port and your
                          warehouse or distribution facility. We also manage
                          custom clearance and documentation at origin and
                          destination. For your convenience, we handle both
                          full-container load (FCL) and less-than-container load
                          (LCL) overseas freight shipping.
                        </span>
                      </p>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="#ocean-freight#full-container">
                    <div>
                      <h2 className="lead">FULL CONTAINER</h2>
                      <p className="mt-3">
                        As a full-container load, or FCL, container shipping
                        company, Blue Ocean Marine offers our customers
                        reliable, on-time ocean freight shipping. Through
                        collaboration with a global network of 3PL partners and
                        large shipping companies, we can manage full container
                        loads from port-to-port, port-to-door or door-to-door.
                        In addition, we offer shipping documentation and customs
                        clearance services, as well as warehousing and
                        distribution services. Let Blue Ocean Marine take full
                        responsibility for shipping your full-container load.
                      </p>

                      <h3 className="lead">SEA EXPORT FCL SERVICES</h3>
                      <p className="mt-3">
                        We offer export FCL shipping solutions that leverage
                        longstanding relationships with major shipping lines.
                        Our services include:
                      </p>

                      <ul>
                        <li>Door-to-door or door-to-port services</li>
                        <li>Hazardous cargo</li>
                        <li>Oversized cargo (flat racks, open tops, etc.)</li>
                        <li>Refrigerated cargo</li>
                        <li>Containerized automobile transport</li>
                        <li>Cargo handling agent</li>
                        <li>ISO tanks (edibles, additives, chemicals)</li>
                        <li>
                          Project cargo (oil and gas industry, manufacturing,
                          industrial, heavy equipment, machinery, out-of-gauge
                          cargo, boats)
                        </li>
                        <li>Door-to-door or port-to-door services</li>
                      </ul>

                      <h3 className="lead">SEA IMPORT FCL SERVICES</h3>
                      <p className="mt-3">
                        We offer full FCL shipping solutions from worldwide
                        destinations. Our services include:
                      </p>

                      <ul>
                        <li>Door-to-door or port-to-door services</li>
                        <li>Hazardous cargo</li>
                        <li>Break-bulk cargo</li>
                        <li>Oversized cargo</li>
                        <li>Refrigerated cargo</li>
                        <li>Cargo handling agent</li>
                        <li>ISO tanks (edibles, additives, chemicals)</li>
                        <li>
                          Project cargo (oil and gas industry, manufacturing,
                          industrial, heavy equipment, machinery, out-of-gauge
                          cargo, boats)
                        </li>
                        <li>Door-to-door or port-to-door services</li>
                      </ul>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="#ocean-freight#less-than-full-container">
                    <div>
                      <h2 className="lead">LESS-THAN-FULL CONTAINER</h2>
                      <p className="mt-3">
                        <span>
                          Blue Ocean Marine is a premier less-than-container
                          load, or LCL, international shipping brokerage
                          company. We offer regular, reliable import and export
                          LCL consolidation services with weekly sailings to and
                          from a large number of worldwide destinations. Our
                          goal is to minimize transport times and reduce cargo
                          handling while offering competitive and affordable
                          rates.
                        </span>
                        <br />
                        <span>
                          How does LTL shipping work? If you don't have a full
                          container to ship, we consolidate your shipment with
                          the LTL shipments of other customers. By consolidating
                          multiple less-than-container loads into a full
                          container, customers share the cost of the full
                          container and can ship lower volumes at cost-effective
                          full-container rates. This flexible solution allows
                          you to take advantage of economies of scale and
                          frequency of routing not otherwise available to small
                          shippers.
                        </span>
                        <br />
                        <span>
                          Don't wait until you have a full container! Let Blue
                          Ocean Marine keep your products moving by
                          consolidating your shipments with other merchants'
                          cargo. And be sure to combine this service with our
                          other offerings — real-time tracking, customs
                          documentation, door-to-door service, and warehousing
                          and distribution — for a truly custom end-to-end
                          transport solution at a competitive price.
                        </span>
                      </p>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="#shipping-agency">
                    <div>
                      <h2 className="lead">SHIPPING AGENCY</h2>
                      <ul>
                        <li>Petroleum Services</li>
                        <li>Ship Management, Operations & Logistics</li>
                        <li>Vessels port Call</li>
                        <li>Arrival and Departure Procedures</li>
                        <li>Meet and Assist Services</li>
                        <li>Accommodation Arrangements</li>
                        <li>Transportation Solutions</li>
                        <li>Crew Change Services</li>
                        <li>Coast Guard Permits I G26 & ports permits.</li>
                        <li>Liaison with Egyptian Authorities</li>
                        <li>Disposal of Dirty Oil, Mud, and Garbage</li>
                        <li>Diving Services & inspection</li>
                        <li>Suez Canals and Straits Transit</li>
                        <li>Maintenance of Marine Units</li>
                        <li>Issuance of Certificates</li>
                        <li>Chartering Vessel Rentals</li>
                        <li>Fuel and Fresh Water Supply</li>
                        <li>
                          Supply vessels operate within the petroleum industry.
                        </li>
                        <li>Rigs/Vessels docking repairs.</li>
                        <li>Dealing with class surveyors</li>
                        <li>Custom clearance for petroleum equipment's</li>
                        <li>Handling with P&I club</li>
                        <li>Managing towing operation</li>
                        <li>Managing offshore operation rigs</li>
                        <li>
                          Customs clearance, warehouse management, freight
                          forwarding and trucking
                        </li>
                      </ul>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="#warehousing">
                    <div>
                      <h2 className="lead">WAREHOUSING</h2>
                      <p>
                        <span>
                          Blue Ocean Marine offers freight and cargo warehousing
                          and distribution services through our liaisons with a
                          network of third-party vendor partners. The facilities
                          we utilize are strategically located across the
                          country to provide convenient proximity to transport
                          and easy access for equipment. When you request this
                          service, we select a facility of the right size and
                          type that is situated where you need it.
                        </span>

                        <br />
                        <br />
                        <span>
                          Blue Ocean Marine accommodates shipments or orders on
                          a short- or long-term basis, and as the service
                          provider, we accept full responsibility for your
                          inventory. From consolidation/deconsolidation and
                          pick-and-pack to transloading and cross-docking, we
                          manage your products to help you improve your supply
                          chain.
                        </span>
                      </p>
                      <p className="lead mt-4">
                        Our 3PL warehousing services include:
                      </p>
                      <ul>
                        <li>Storage</li>
                        <li>Marking and labeling</li>
                        <li>Packing and crating</li>
                        <li>Consolidation and vendor management services</li>
                      </ul>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="#project-cargo">
                    <div>
                      <h2 className="lead">PROJECT CARGO</h2>
                      <p>
                        <span>
                          Blue Ocean Marine transports oversized, out-of-gauge
                          and/or heavy lift cargo — such as heavy machinery,
                          excavators and oilfield equipment — that cannot be
                          transported in a closed container. We accommodate this
                          type of cargo on flat or open top containers, where
                          possible, or a break bulk vessel.
                        </span>

                        <br />
                        <span>
                          If you're seeking to transport bulk, oversized and
                          project cargo overseas, look no further than Blue
                          Ocean Marine. Our freight professionals bring many
                          years of experience and a wealth of knowledge in
                          logistics project management, allowing us to create
                          cost-effective solutions for shipping even your most
                          challenging cargo.
                        </span>
                      </p>
                      <p className="lead mt-4">
                        Our project cargo services include:
                      </p>
                      <ul>
                        <li>
                          Flat rack and open top containers for out-of-gauge
                          (OOG) cargo
                        </li>
                        <li>Break bulk shipping</li>
                        <li>Rigging to safely raise and lower cargo</li>
                        <li>Heavy haul trucking</li>
                        <li>Rail transportation</li>
                        <li>Vessel charters</li>
                        <li>Aircraft charters</li>
                        <li>Surveying, including route-specific</li>
                        <li>Customs clearance and permit applications</li>
                      </ul>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="#customs-clearance">
                    <div>
                      <h2 className="lead">CUSTOMS CLEARANCE</h2>
                      <p>
                        Established more than three decades ago, Blue Ocean
                        Marine has been a beacon of reliability in the maritime
                        industry. Our extensive experience has honed our skills,
                        allowing us to anticipate and address the unique
                        challenges that can arise during customs clearance. You
                        can trust us to handle your shipments with the seasoned
                        expertise that only comes from years of navigating the
                        intricate details of international trade.
                      </p>
                      <p className="lead mt-4">
                        In-House Customs Clearance Agents:
                      </p>
                      <p>
                        At the heart of our success is our team of over 30
                        in-house customs clearance agents. These professionals
                        bring a wealth of knowledge and dedication to every
                        clearance process. Their deep understanding of customs
                        regulations, documentation requirements, and the
                        evolving landscape of global trade ensures that your
                        shipments are in capable hands. With a commitment to
                        staying up-to-date on the latest industry developments,
                        our agents are equipped to handle even the most complex
                        clearance scenarios.
                      </p>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="#inland-haulage">
                    <div>
                      <h2 className="lead">INLAND HAULAGE</h2>
                      <p>
                        Navigating the last leg of your shipment's journey is as
                        crucial as its oceanic voyage. Blue Ocean Logistics
                        provides top-tier inland haulage services, guaranteeing
                        the safe and timely transportation of your goods from
                        the port to the final destination. Our fleet of
                        well-maintained vehicles, coupled with experienced
                        drivers, ensures that your cargo reaches its inland
                        destination efficiently and securely.
                      </p>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Container>
        </Tab.Container>
      </div>
    </div>
  );
};

export default Services;

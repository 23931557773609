import "./styles.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useEffect, useState } from "react";
import ServicesItem from "components/atoms/servicesItem";
import { HOME_SERVICES_DATA } from "constants/home_services_data";
import {
  faAnchor,
  faBoxOpen,
  faLaptop,
  faShip,
  faTruckFront,
} from "@fortawesome/free-solid-svg-icons";

const ServicesComponent = () => {
  const [containerClassName, setContainerClassName] = useState("containerBg0");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleColHover = (index: number) => {
    setContainerClassName(`containerBg${index}`);
  };

  return (
    <div className="servicesComponentContainer mt-5 mb-3">
      <Container className={containerClassName}>
        <Row className={`servicesComponentRow`}>
          <Col
            className={`servicesComponentCol ${
              isMobile ? "colMobile" : "colWeb"
            } col-mr`}
            onMouseEnter={!isMobile ? () => handleColHover(1) : () => {}}
            onMouseLeave={!isMobile ? () => handleColHover(0) : () => {}}
          >
            <ServicesItem
              icon={faShip}
              title="Ocean Frieght"
              mainHref="/#services#ocean-freight"
              children={
                isMobile || containerClassName === "containerBg1"
                  ? HOME_SERVICES_DATA.oceanFrieght
                  : []
              }
            />
          </Col>
          <Col
            className={`servicesComponentCol ${
              isMobile ? "colMobile" : "colWeb"
            } col-mr`}
            onMouseEnter={!isMobile ? () => handleColHover(2) : () => {}}
            onMouseLeave={!isMobile ? () => handleColHover(0) : () => {}}
          >
            <ServicesItem
              icon={faAnchor}
              title="Shipping Agency"
              mainHref="/#services#shipping-agency"
            />
          </Col>
          <Col
            className={`servicesComponentCol ${
              isMobile ? "colMobile" : "colWeb"
            } col-mr`}
            onMouseEnter={!isMobile ? () => handleColHover(3) : () => {}}
            onMouseLeave={!isMobile ? () => handleColHover(0) : () => {}}
          >
            <ServicesItem
              icon={faBoxOpen}
              title="Customs Clearance"
              mainHref="/#services#customs-clearance"
            />
          </Col>
          <Col
            className={`servicesComponentCol ${
              isMobile ? "colMobile" : "colWeb"
            } col-mr`}
            onMouseEnter={!isMobile ? () => handleColHover(4) : () => {}}
            onMouseLeave={!isMobile ? () => handleColHover(0) : () => {}}
          >
            <ServicesItem
              icon={faTruckFront}
              title="Inland Haulage"
              mainHref="/#services#inland-haulage"
            />
          </Col>
          <Col
            className={`servicesComponentCol ${
              isMobile ? "colMobile" : "colWeb"
            } col-m0`}
            onMouseEnter={!isMobile ? () => handleColHover(5) : () => {}}
            onMouseLeave={!isMobile ? () => handleColHover(0) : () => {}}
          >
            <ServicesItem
              icon={faLaptop}
              title="Digital Business Solutions"
              children={
                isMobile || containerClassName === "containerBg5"
                  ? HOME_SERVICES_DATA.digitalBusinessSolutions
                  : []
              }
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ServicesComponent;

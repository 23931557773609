import "./styles.scss";

type props = {
  children: React.ReactNode;
  className?: string;
};

const Headline = ({ children, className, ...restProps }: props) => {
  const combinedClassName = `headline ${className || ""}`;

  return (
    <h2 className={combinedClassName} {...restProps}>
      {children}
    </h2>
  );
};

export default Headline;

import Container from "react-bootstrap/Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition, faNotdef } from "@fortawesome/free-solid-svg-icons";
import "./styles.scss";

type props = {
  label?: string;
  sublabel?: string;
  icon?: IconDefinition;
};

const FeatureItem = ({
  label = "Label",
  sublabel = "Sublabel",
  icon = faNotdef,
}: props) => {
  return (
    <Container className="featureItem mb-2">
      <FontAwesomeIcon className="icon me-4" icon={icon} size="2xl" />
      <div className="text-start">
        <h1 className="label m-0">{label}</h1>
        <h6 className="m-0 mt-1">{sublabel}</h6>
      </div>
    </Container>
  );
};

export default FeatureItem;

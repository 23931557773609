import { IMAGES } from "common/assets";
import "./styles.scss";
import Image from "react-bootstrap/Image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { fafacebook } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faInstagram,
  faLinkedin,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

const MainFooter = () => {
  return (
    // <div className="footerContainer">
    //   <div className="logoContainer">
    //     <a href="/">
    //       <Image src={IMAGES.logo} alt="Blue Ocean Marine" fluid />
    //     </a>
    //   </div>
    //   <a href="/terms-of-use">
    //     <h2 className="lead">Terms of Use</h2>
    //   </a>
    // </div>
    <footer
      className="footerContainer text-center text-lg-start text-white"
      style={{ backgroundColor: "#1c2331" }}
    >
      {/* <section
        className="d-flex justify-content-between p-4"
        style={{ backgroundColor: "#6351ce" }}
      >
        <div className="me-5">
          <span>Get connected with us on social networks:</span>
        </div>

        <div>
          <a
            href="https://www.facebook.com/blueoceanmarine1984"
            className="text-white me-4"
          >
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
          <a
            href="https://www.instagram.com/blueoceanmarine1984"
            className="text-white me-4"
          >
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a
            href="https://www.linkedin.com/company/blue-ocean-marine"
            className="text-white me-4"
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          <a href="http://wa.me/201200112284" className="text-white me-4">
            <FontAwesomeIcon icon={faWhatsapp} />
          </a>
        </div>
      </section> */}

      <section className="d-flex justify-content-between p-4">
        <div className="container text-center text-md-start">
          <div className="row mt-3">
            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
              <h6 className="text-uppercase fw-bold">Blue Ocean Marine</h6>
              <hr
                className="mt-0 d-inline-block mx-auto"
                style={{
                  width: "60px",
                  color: "#12a6ba",
                  height: "2px",
                }}
              />
              <div>
                <a href="/">
                  <Image
                    src={IMAGES.logoWhite}
                    alt="Blue Ocean Marine"
                    fluid
                    className="logoContainer"
                  />
                </a>
              </div>
            </div>
            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 className="text-uppercase fw-bold">Support</h6>
              <hr
                className="mb-4 mt-0 d-inline-block mx-auto"
                style={{
                  width: "60px",
                  color: "#12a6ba",
                  height: "2px",
                }}
              />
              <p>
                <a href="/#contact-us">Contact Us</a>
              </p>
              <p>
                <a href="/#contact-us">Help Center</a>
              </p>
              <p>
                <a href="/#privacy">Privacy</a>
              </p>
              <p>
                <a href="/#terms-of-use">Security</a>
              </p>
              <p>
                <a href="/#legal-terms">Legal</a>
              </p>
              <p>
                <a href="/#terms-of-use">Terms & Conditions</a>
              </p>
              <p>
                <a href="/#terms-of-use">Cookies</a>
              </p>
              <p>
                <a
                  href="https://wuzzuf.net/jobs/careers/Blue-Ocean-Marine-Egypt-79487"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Careers
                </a>
              </p>
            </div>

            {/* <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 className="text-uppercase fw-bold">Social Links</h6>
              <hr
                className="mb-4 mt-0 d-inline-block mx-auto"
                style={{
                  width: "60px",
                  backgroundColor: "#12a6ba",
                  height: "2px",
                }}
              />
              <p>
                <a href="#!" className="text-white">
                  Legal
                </a>
              </p>
              <p>
                <a href="#!" className="text-white">
                  Terms & Conditions
                </a>
              </p>
              <p>
                <a href="#!" className="text-white">
                  Cookies
                </a>
              </p>
            </div> */}

            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold">Social Links</h6>
              <hr
                className="mb-4 mt-0 d-inline-block mx-auto"
                style={{
                  width: "60px",
                  color: "#12a6ba",
                  height: "2px",
                }}
              />
              {/* <p>
                <i className="fas fa-home mr-3"></i> New York, NY 10012, US
              </p>
              <p>
                <i className="fas fa-envelope mr-3"></i> info@example.com
              </p>
              <p>
                <i className="fas fa-phone mr-3"></i> + 01 234 567 88
              </p>
              <p>
                <i className="fas fa-print mr-3"></i> + 01 234 567 89
              </p> */}

              <p>
                <a
                  href="https://www.facebook.com/blueoceanmarine1984"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="me-4"
                >
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
                <a
                  href="https://www.instagram.com/blueoceanmarine1984"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="me-4"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a
                  href="https://www.linkedin.com/company/blue-ocean-marine"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="me-4"
                >
                  <FontAwesomeIcon icon={faLinkedin} />
                </a>
                <a
                  href="http://wa.me/201200112284"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="me-4"
                >
                  <FontAwesomeIcon icon={faWhatsapp} />
                </a>
              </p>

              <div>
                <h6 className="text-uppercase fw-bold mt-5">Working Hours</h6>
                <hr
                  className="mb-4 mt-0 d-inline-block mx-auto"
                  style={{
                    width: "60px",
                    color: "#12a6ba",
                    height: "2px",
                  }}
                />
                <p>
                  <span className="fw-bold">
                    Saturday:
                    <span className="fw-normal"> 9:00 AM - 4:30 PM</span>
                  </span>
                  <br />
                  <span className="fw-bold">
                    Sunday:
                    <span className="fw-normal"> 9:00 AM - 4:30 PM</span>
                  </span>
                  <br />
                  <span className="fw-bold">
                    Monday:
                    <span className="fw-normal"> 9:00 AM - 4:30 PM</span>
                  </span>
                  <br />
                  <span className="fw-bold">
                    Tuesday:
                    <span className="fw-normal"> 9:00 AM - 4:30 PM</span>
                  </span>
                  <br />
                  <span className="fw-bold">
                    Wednesday:
                    <span className="fw-normal"> 9:00 AM - 4:30 PM</span>
                  </span>
                  <br />
                  <span className="fw-bold">
                    Thursday:
                    <span className="fw-normal"> 9:00 AM - 4:30 PM</span>
                  </span>
                  <br />
                  <span className="fw-bold">
                    Friday:
                    <span className="fw-normal"> OFF</span>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="text-center p-3"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
      >
        © 2024
        <a href="/" target="_blank" rel="noopener noreferrer">
          {" "}
          Blue Ocean Marine
        </a>
        . All rights reserved.
      </div>
    </footer>
  );
};

export default MainFooter;

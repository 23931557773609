import Headline from "components/atoms/headline";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import Image from "react-bootstrap/Image";
import { CONTAINERS_DATA } from "constants/containers_data";
import "./styles.scss";

const Containers = () => {
  return (
    <Container id="containers" className="containersContainer">
      <div className="text-center">
        <Headline className="display-6">CONTAINERS</Headline>
      </div>

      <Table responsive bordered hover className="my-3">
        <thead className="align-middle text-center">
          <tr>
            <th>TYPE OF CONTAINER</th>
            <th></th>
            <th>INTERIOR DIMENSIONS (MM)</th>
            <th>DOOR OPENING (MM)</th>
            <th>LOAD CAPACITY (CU-M)</th>
            <th>MAX-PAYLOAD (KG)</th>
            <th>TARE (KG)</th>
            <th>MAX-GROSS (KG)</th>
          </tr>
        </thead>
        <tbody className="align-middle">
          {CONTAINERS_DATA.map((item) => (
            <tr key={item.containerType}>
              <td>{item.containerType}</td>
              <td>
                <Image src={item.imageUrl} width={125} />
              </td>
              {item.interiorDimentions ? (
                <td>
                  <span>L: {item.interiorDimentions.length}</span>
                  <br />
                  <span>W: {item.interiorDimentions.width}</span>
                  <br />
                  <span>H: {item.interiorDimentions.height}</span>
                  {item.interiorDimentions.MLH ? (
                    <>
                      <br />
                      <span>MLH: {item.interiorDimentions.MLH}</span>
                    </>
                  ) : (
                    <></>
                  )}
                </td>
              ) : (
                <td>-</td>
              )}
              {item.doorOpening ? (
                <td>
                  {item.doorOpening.extra ? (
                    <>
                      <span>{item.doorOpening.extra}</span>
                      <br />
                    </>
                  ) : (
                    <></>
                  )}
                  <span>W: {item.doorOpening.width}</span>
                  <br />
                  <span>{item.doorOpening.height}</span>
                </td>
              ) : (
                <td>-</td>
              )}
              <td>{item.loadCapicity}</td>
              <td>{item.maxPayload}</td>
              <td>{item.tare}</td>
              <td>{item.maxGross}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default Containers;
